<template>
  <Modal :open="true" @close="$emit('closed')" customCls="w-full m-2 lg:w-1/4">
    <template #title>
      <h3 class="text-lg leading-6 font-medium text-gray-900">Parameters</h3></template
    >
    <template #content>
      <Form
        @submit="handleSubmit"
        :initialValues="initialValues"
        :validationSchema="schema"
        class="text-left flex flex-col gap-4"
        v-slot="{ isSubmitting }"
      >
        <FloatField name="confidenceAlpha" label="Confidence alpha" />
        <FloatField name="tolerancePercentile" label="Tolerance percentile" />
        <FloatField name="toleranceAlpha" label="Tolerance alpha" />
        <button
          type="submit"
          class="focus:outline-none flex justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-600 focus:ring-0 disabled:cursor-default disabled:bg-gray-300"
          :disabled="isSubmitting"
        >
          <LoadingSpinner v-if="isSubmitting" size="w-5 h-5" color="white" class="mr-2" />
          Recalculate
        </button>
      </Form>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { Form, SubmissionHandler } from "vee-validate";
import { computed, Ref } from "vue";
import * as yup from "yup";
import FloatField from "shared/components/forms/FloatField.vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useCalculateUnitValueAggregates } from "@/composables/unitValues";
import { UnitValueAggregateParameters } from "@/types/UnitValue";

const props = defineProps<{
  confidenceAlpha: number;
  tolerancePercentile: number;
  toleranceAlpha: number;
}>();

const emit = defineEmits<{ (eventName: "closed"): void }>();

const minConfidenceAlpha = 0.01;
const maxConfidenceAlpha = 0.99;
const minTolerancePercentile = 0.01;
const maxTolerancePercentile = 0.99;
const minToleranceAlpha = 0.01;
const maxToleranceAlpha = 0.99;

const schema = yup.object({
  confidenceAlpha: yup
    .number()
    .required("Confidence alpha is required")
    .typeError("Confidence alpha should be a valid number")
    .min(minConfidenceAlpha, `Minimum confidence alpha is ${minConfidenceAlpha}`)
    .max(maxConfidenceAlpha, `Maximum confidence alpha is ${maxConfidenceAlpha}`),
  tolerancePercentile: yup
    .number()
    .required("Tolerance percentile is required")
    .typeError("Tolerance percentile should be a valid number")
    .min(minTolerancePercentile, `Minimum tolerance percentile is ${minTolerancePercentile}`)
    .max(maxTolerancePercentile, `Maximum tolerance percentile is ${maxTolerancePercentile}`),
  toleranceAlpha: yup
    .number()
    .required("Tolerance alpha is required")
    .typeError("Tolerance alpha should be a valid number")
    .min(minToleranceAlpha, `Minimum tolerance alpha is ${minToleranceAlpha}`)
    .max(maxToleranceAlpha, `Maximum tolerance alpha is ${maxToleranceAlpha}`),
});

type ConfidenceAlphaForm = UnitValueAggregateParameters;

const initialValues: Ref<UnitValueAggregateParameters> = computed(() => ({
  confidenceAlpha: props.confidenceAlpha,
  tolerancePercentile: props.tolerancePercentile,
  toleranceAlpha: props.toleranceAlpha,
}));

const { calculateUnitValueAggregates } = useCalculateUnitValueAggregates();

const handleSubmit: SubmissionHandler = async (genericObject) => {
  const values = genericObject as ConfidenceAlphaForm;
  await calculateUnitValueAggregates(values);
  emit("closed");
};
</script>
