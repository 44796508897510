<template>
  <Modal :open="open" @close="$emit('closeModal')">
    <template #content>
      <div class="col-span-1">
        <h1 class="text-xl font-extrabold text-gray-700">Export Controlling Excel</h1>
        <hr class="my-2 h-px bg-gray-300 border-0 dark:bg-gray-700" />
        <div class="mt-4 grid grid-cols-9 gap-4 flex flex-col">
          <div class="col-span-2">
            <label for="start_time" class="block text-sm font-medium leading-6 text-gray-900"
              >Start Time</label
            >
            <input
              v-model="startDate"
              type="date"
              max="9999-12-12T00:00:00.00"
              id="searchDate"
              class="form-control block w-full shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm border-gray-300 rounded-md py-2"
            />
          </div>
          <div class="col-span-2">
            <label for="end_time" class="block text-sm font-medium leading-6 text-gray-900"
              >End Time</label
            >
            <input
              v-model="endDate"
              type="date"
              max="9999-12-12T00:00:00.00"
              id="searchDate"
              class="form-control block w-full shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm border-gray-300 rounded-md py-2"
            />
          </div>
          <div class="col-span-2">
            <label for="end_time" class="block text-sm font-medium leading-6 text-gray-900"
              >Project Status</label
            >
            <select
              name="status"
              id="status"
              class="form-control block w-full shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm border-gray-300 rounded-md py-2"
              v-model="projectStatus"
            >
              <option value="active">Active</option>
              <option value="completed">Completed</option>
              <option :value="null">All projects</option>
            </select>
          </div>
          <div class="col-span-2">
            <label for="end_time" class="block text-sm font-medium leading-6 text-gray-900"
              >Tracking Group</label
            >
            <select
              name="status"
              id="status"
              class="form-control block w-full shadow-sm focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm border-gray-300 rounded-md py-2"
              v-model="userFilter"
            >
              <option :value="null">All Tracking Groups</option>
              <option v-for="entity in trackingEntities" :key="entity._id" :value="entity.name">
                {{ entity.name }}
              </option>
            </select>
          </div>
          <div class="col-span-1 pt-6">
            <button
              type="button"
              class="inline-flex w-full justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              @click="exportControllingXlsx()"
            >
              <LoadingSpinner class="mr-2" v-if="loadingExport" size="h-5 w-5" />
              Export
            </button>
          </div>
        </div>
        <div class="pt-4" v-if="showExportError">
          <p class="text-sm text-red-500">{{ exportErrorMessage }}</p>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as XLSX from "xlsx";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useTrackingEntities } from "shared/composables/validation";
import logger from "shared/services/logger";
import { ProjectStatus } from "shared/types/Project";
import ControllingRepository from "@/repositories/ControllingRepository";

export default defineComponent({
  name: "ControllingExport",
  props: ["open"],
  emits: ["closeModal"],
  components: {
    Modal,
    LoadingSpinner,
  },

  data() {
    return {
      startDate: "" as string,
      endDate: "" as string,
      projectStatus: null as ProjectStatus | null,
      userFilter: null as string | null,
      showExportError: false as boolean,
      exportErrorMessage: "" as string,
      loadingExport: false as boolean,
    };
  },
  methods: {
    exportControllingXlsx() {
      if (!this.startDate || !this.endDate) {
        this.showExportError = true;
        this.exportErrorMessage = "Form values missing.";
        return;
      }

      if (this.startDate > this.endDate) {
        this.showExportError = true;
        this.exportErrorMessage = "Start should be before end date.";
        return;
      }
      this.loadingExport = true;

      return ControllingRepository.loadPrdValidationSummaryExportData(
        this.startDate,
        this.endDate,
        this.projectStatus,
        this.userFilter,
      )
        .then((data) => {
          const workbook = XLSX.utils.book_new();
          const worksheetPrdControlling = XLSX.utils.aoa_to_sheet(data);
          XLSX.utils.book_append_sheet(workbook, worksheetPrdControlling, "PRD Controlling");
          XLSX.writeFile(workbook, `prd_controlling_export_${this.startDate}_${this.endDate}.xlsx`);
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to load export summary.");
          }
        })
        .finally(() => {
          this.loadingExport = false;
          this.showExportError = false;
          this.exportErrorMessage = "";
        });
    },
  },
  setup() {
    const { trackingEntities } = useTrackingEntities();
    return {
      trackingEntities,
    };
  },
});
</script>

<style></style>
