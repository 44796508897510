<template>
  <Modal :open="true" @close="emit('close')" customCls="w-full m-5 xl:w-1/3">
    <template #title>Organizations</template>
    <template #content>
      <div class="h-[300px] text-left flex flex-col gap-4">
        <div class="flex-1 flex flex-col gap-4">
          <Combobox as="div" v-model="selectedOrganizationIds" multiple>
            <div class="relative mt-2">
              <ComboboxInput
                class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                :displayValue="getDisplayValue"
                autocomplete="one-time-code"
                :readonly="true"
              />
              <ComboboxButton
                class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
              >
                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </ComboboxButton>
              <ComboboxOptions
                v-if="organizations.length > 0"
                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <OrganizationModalItem
                  v-for="organizationWithChildren in organizationsWithChildren"
                  :organizationWithChildren="organizationWithChildren"
                  :key="organizationWithChildren._id"
                  :parent="null"
                  :parentChildren="organizationsWithChildren"
                />
              </ComboboxOptions>
            </div>
          </Combobox>
        </div>
        <button
          type="submit"
          :disabled="isUpdateOrganizationLoading"
          class="focus:outline-none inline-flex w-full items-center justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-yellow-700 disabled:bg-gray-200"
          @click="handleSaveClick"
        >
          Save
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOptions } from "@headlessui/vue";
import { ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import { computed, ref } from "vue";
import Modal from "shared/components/modals/Modal.vue";
import { useUpdateOrganization } from "shared/composables/organization";
import { Organization, OrganizationWithChildren } from "shared/types/Organization";
import { Project } from "shared/types/Project";
import OrganizationModalItem from "@/views/project_console/components/OrganizationModalItem.vue";

const props = defineProps<{
  project: Project;
  organizations: Organization[];
}>();
const emit = defineEmits<{
  (eventName: "close"): void;
  (eventName: "update"): void;
}>();

const { updateOrganization, isLoading: isUpdateOrganizationLoading } = useUpdateOrganization();

const selectedOrganizationIds = ref<string[]>(
  props.organizations
    .filter((organization) =>
      organization.explicit_projects.some(
        (project) =>
          project.customer_name === props.project.customer_name &&
          project.site_id === props.project.site_id,
      ),
    )
    .map((organization) => organization._id),
);

const organizationsById = computed(() =>
  props.organizations.reduce((acc, organization) => {
    acc[organization._id] = organization;
    return acc;
  }, {} as Record<string, Organization>),
);

const organizationsByParentId = computed(() =>
  props.organizations.reduce((acc, organization) => {
    if (!organization.parent_id) {
      return acc;
    }
    if (!acc[organization.parent_id]) {
      acc[organization.parent_id] = [];
    }
    (acc[organization.parent_id] as Organization[]).push(organization);
    return acc;
  }, {} as Record<string, Organization[] | undefined>),
);

const createOrganizationsWithChildren = (
  organizations: Organization[],
): OrganizationWithChildren[] => {
  const organizationWithLabel = organizations.map((organization) => ({
    _id: organization._id,
    label: organization.name,
    normalizedLabel: organization.name,
    children: createOrganizationsWithChildren(
      organizationsByParentId.value[organization._id] || [],
    ),
    matchType: undefined,
  }));
  organizationWithLabel.sort((a, b) => a.label.localeCompare(b.label));
  return organizationWithLabel;
};

const organizationsWithChildren = computed(() => {
  const rootOrganizations = props.organizations.filter((organization) => !organization.parent_id);
  return createOrganizationsWithChildren(rootOrganizations);
});

const getDisplayValue = (organizationIdsArg: unknown) =>
  (organizationIdsArg as string[])
    .map((organizationId) => organizationsById.value[organizationId])
    .filter((organization) => organization)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((organization) => organization.name)
    .join(", ");

const updateOrganizationIfNeeded = async (
  organization: Organization,
  selectedOrganizationIds: Set<string>,
) => {
  if (
    selectedOrganizationIds.has(organization._id) &&
    !organization.explicit_projects.some(
      (project) =>
        project.customer_name === props.project.customer_name &&
        project.site_id === props.project.site_id,
    )
  ) {
    await updateOrganization({
      _id: organization._id,
      name: organization.name,
      parent_id: organization.parent_id,
      explicit_projects: [
        ...organization.explicit_projects,
        { customer_name: props.project.customer_name, site_id: props.project.site_id },
      ],
    });
  }
  if (
    !selectedOrganizationIds.has(organization._id) &&
    organization.explicit_projects.some(
      (project) =>
        project.customer_name === props.project.customer_name &&
        project.site_id === props.project.site_id,
    )
  ) {
    await updateOrganization({
      _id: organization._id,
      name: organization.name,
      parent_id: organization.parent_id,
      explicit_projects: organization.explicit_projects.filter(
        (project) =>
          !(
            project.customer_name === props.project.customer_name &&
            project.site_id === props.project.site_id
          ),
      ),
    });
  }
};

const handleSaveClick = async () => {
  let hasError = false;
  const selectedOrganizationIdsSet = new Set(selectedOrganizationIds.value);
  for (const organization of props.organizations) {
    await updateOrganizationIfNeeded(organization, selectedOrganizationIdsSet).catch(() => {
      hasError = true;
    });
  }
  if (!hasError) {
    emit("update");
  }
};
</script>
