<template>
  <div
    class="inline-flex items-center rounded-full py-0.5 px-2.5 text-sm font-medium"
    v-if="entity"
    :style="{
      backgroundColor: backgroundColor,
      color: entity.color,
    }"
  >
    {{ entity.name }}
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { TrackingEntityEntry } from "@/types/Validation";

const props = defineProps<{
  entity: TrackingEntityEntry | undefined;
}>();

const backgroundColor = computed(() => {
  return props.entity?.color.replace(/rgba\(([^,]+),([^,]+),([^,]+),[^)]+\)/, "rgba($1,$2,$3,0.1)");
});
</script>
