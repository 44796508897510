<template>
  <div
    class="relative w-full h-14 flex justify-between items-center border-b border-gray-300 px-6 py-2"
  >
    <button
      type="button"
      class="inline-flex items-center md:px-4 md:py-1.5 rounded-md font-bold border shadow transition-colors duration-150 text-gray-400 border-gray-400 hover:border-yellow hover:bg-yellow hover:text-white bg-gray-50"
      @click="goBack"
    >
      <ArrowLongLeftIcon class="h-5" />
    </button>

    <div class="absolute left-1/2 transform -translate-x-1/2" v-if="groupService">
      <span class="font-bold mr-2">
        {{ groupService.sequence.customer_name }}/{{ groupService.sequence.site_id }}/{{
          groupService.sequence.camera_id
        }}/{{ groupService.sequence.date }}
      </span>
      <span class="text-sm text-gray-600">
        {{ groupService.sequence.frames[0]?.frame_id }}
        -
        {{ groupService.sequence.frames.at(-1)?.frame_id }}
      </span>
      <div
        v-if="groupService.sequence.status !== 'incomplete'"
        :class="[
          'ml-4 inline-flex items-center rounded-xl px-3 py-1 text-xs font-medium',
          {
            'bg-green-200 text-green-700': groupService.sequence.status === 'completed',
            'bg-purple-200 text-purple-700': groupService.sequence.status === 'approved',
          },
        ]"
      >
        {{ groupService.sequence.status }}
      </div>
    </div>

    <div class="flex gap-2" v-if="groupService">
      <button
        type="button"
        class="inline-flex items-center rounded-md px-3 py-2 text-xs font-medium text-white shadow-sm bg-yellow-500 hover:bg-yellow-700"
        @click="groupService.saveAnnotations()"
      >
        <span>Save</span>
      </button>
      <button
        type="button"
        :class="[
          'inline-flex items-center rounded-md px-3 py-2 text-xs font-medium hover:bg-green-600 hover:text-white shadow-sm',
          groupService.sequence.status === 'completed'
            ? 'text-green bg-white border border-green-500'
            : 'text-white border-transparent bg-green-500',
        ]"
        @click="markSequenceStatus('completed')"
      >
        <span>Complete</span>
      </button>
      <button
        v-if="hasPermission('pct_admin')"
        type="button"
        :class="[
          'inline-flex items-center rounded-md px-3 py-2 text-xs font-medium hover:bg-purple-600 hover:text-white shadow-sm',
          groupService.sequence.status === 'approved'
            ? 'text-purple bg-white border border-purple-500'
            : 'text-white border-transparent bg-purple-500',
        ]"
        @click="markSequenceStatus('approved')"
      >
        <span>Approve</span>
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ArrowLongLeftIcon } from "@heroicons/vue/24/solid";
import { PropType } from "vue";
import { useRouter } from "vue-router";
import { useCustomToast } from "shared/composables/toast";
import logger from "shared/services/logger";
import SequenceRepository from "@/repositories/SequenceRepository";
import GroupService from "@/views/person_gad/services/groupService";

const props = defineProps({
  groupService: {
    type: Object as PropType<GroupService>,
  },
  query: {
    type: Object as PropType<Record<string, unknown>>,
    default: () => {},
  },
});

const router = useRouter();
const toast = useCustomToast();

const markSequenceStatus = async (status: "completed" | "approved") => {
  if (!props.groupService) {
    return;
  }

  const saveStatus = status === props.groupService.sequence.status ? "incomplete" : status;

  try {
    await props.groupService.saveAnnotations(saveStatus);
  } catch (e: unknown) {
    logger.error(e);
    toast.error("Something went wrong");
  }
};

const goBack = () => {
  const query = SequenceRepository.objectToUrlSearchParams(props.query).toString();

  router.push(`/person-gad?${query}&refresh=true`);
};
</script>
