<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto" :src="logoMultipleNoPaddingSrc" alt="Workflow" />
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form @submit.prevent="login" class="space-y-6" action="#" method="POST">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700"> Email </label>
            <div class="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autocomplete="email"
                :required="true"
                v-model="email"
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-oaiGray-100 focus:border-oaiGray-100 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray-700"> Password </label>
            <div class="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                :required="true"
                v-model="password"
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-oaiGray-100 focus:border-oaiGray-100 sm:text-sm"
              />
            </div>
          </div>

          <div class="rounded-md bg-red-50 p-4" v-show="passwordAlert">
            <div class="flex">
              <div class="shrink-0">
                <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">Wrong email or password</h3>
              </div>
            </div>
          </div>
          <div class="pt-2">
            <button
              type="submit"
              :disabled="loginLoading"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              <div v-if="loginLoading">
                <svg
                  class="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
              <div v-else>Sign In</div>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { XCircleIcon } from "@heroicons/vue/24/solid";
import { AxiosError } from "axios";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import logoMultipleNoPaddingSrc from "shared/assets/imgs/logo/logo-multiple-no-padding.svg";
import { useGetReturnUrl } from "shared/composables/auth";
import logger from "shared/services/logger";
import AuthMixins from "@/mixins/AuthMixins";

export default defineComponent({
  name: "Login",
  components: {
    XCircleIcon,
  },
  mixins: [AuthMixins],
  methods: {
    async login(): Promise<void> {
      this.loginLoading = true;
      this.passwordAlert = false;

      try {
        await this.signIn(this.email, this.password);
        this.router.push(this.getReturnUrl());
      } catch (error) {
        const is401 = (error as AxiosError)?.response?.status === 401;
        const is403 = (error as AxiosError)?.response?.status === 403;
        if (
          (error as Error)?.message !== "Incorrect username or password." &&
          (error as Error)?.message !== "Network error" &&
          !is403 &&
          !is401
        ) {
          logger.error("Unable to sign in", error as Error);
        }
        this.passwordAlert = true;
        this.loginLoading = false;
      }
    },
  },
  data() {
    return {
      email: "" as string,
      password: "" as string,
      passwordAlert: false as boolean,
      loginLoading: false as boolean,
      logoMultipleNoPaddingSrc,
    };
  },
  setup() {
    const router = useRouter();
    const getReturnUrl = useGetReturnUrl();

    return { router, getReturnUrl };
  },
});
</script>
