<template>
  <div class="mx-auto px-4 py-6">
    <h1 class="text-green-gray-900 text-3xl font-extrabold">Projekt hinzufügen</h1>
    <form @submit.prevent="addProject" class="divide-y-green-gray-200 mt-6 space-y-8 divide-y">
      <div v-for="field in fields" :key="field.field_identifier">
        <div class="max-w-3xl pt-2">
          <label
            :for="field.field_identifier"
            class="text-green-gray-900 block text-sm font-medium"
            >{{ field.field_name }}</label
          >
          <Combobox
            v-if="field.field_identifier === 'country_code'"
            as="div"
            v-model="selectedCountry"
            @update:modelValue="query = ''"
          >
            <div class="relative mt-2">
              <ComboboxInput
                class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                @change="query = $event.target.value"
                :displayValue="(country) => country && `${country.name} (${country.code})`"
                autocomplete="one-time-code"
              />
              <ComboboxButton
                class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
              >
                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </ComboboxButton>
              <ComboboxOptions
                v-if="filteredCountries.length > 0"
                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <ComboboxOption
                  v-for="country in filteredCountries"
                  :key="country.code"
                  :value="country"
                  as="template"
                  v-slot="{ active, selected }"
                >
                  <li
                    :class="[
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-orange-600 text-white' : 'text-gray-900',
                    ]"
                  >
                    <span :class="['block truncate', selected && 'font-semibold']">
                      {{ country.name }} ({{ country.code }})
                    </span>

                    <span
                      v-if="selected"
                      :class="[
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                        active ? 'text-white' : 'text-orange-600',
                      ]"
                    >
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
            </div>
          </Combobox>
          <input
            v-else
            :type="field.field_type"
            :name="field.field_identifier"
            :id="field.field_identifier"
            :placeholder="field.placeholder"
            @input="handleInputChange(field, $event)"
            class="border-green-gray-300 text-green-gray-900 mt-1 block w-full rounded-md shadow-sm focus:border-yellow-700 focus:ring-yellow-700 sm:text-sm"
            required
          />
          <span class="text-red" v-if="field.error">{{ field.error }}</span>
          <div class="mt-4 flex gap-2 items-center" v-if="field.field_identifier === 'zip_code'">
            <div
              class="bg-green-200 text-white px-2 py-1 rounded text-sm w-min whitespace-nowrap"
              v-if="weather?.item"
            >
              Weather data found
            </div>
            <div
              class="bg-red-300 text-white px-2 py-1 rounded text-sm w-min whitespace-nowrap"
              v-if="!weather || !weather.item"
            >
              No weather data found
            </div>
            <LoadingSpinner size="w-4 h-4" v-if="isWeatherLoading" />
          </div>
        </div>
      </div>
      <div class="flex justify-end pt-4">
        <button
          type="submit"
          class="focus:outline-none ml-3 inline-flex justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-700 focus:ring-2 focus:ring-yellow-700 focus:ring-offset-2"
        >
          Projekt hinzufügen
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import { parse } from "date-fns";
import debounce from "lodash.debounce";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { countries } from "shared/services/countries";
import logger from "shared/services/logger";
import ProjectRepository from "@/repositories/ProjectRepository";
import WeatherRepository from "@/repositories/WeatherRepository";

export default {
  name: "AddProjectComponent",
  components: {
    LoadingSpinner,
    CheckIcon,
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    ChevronUpDownIcon,
    ComboboxButton,
  },
  computed: {
    filteredCountries() {
      return this.query
        ? countries.filter(
            (country) =>
              country.name.toLowerCase().includes(this.query.toLowerCase()) ||
              country.code.toLowerCase().includes(this.query.toLowerCase()),
          )
        : countries;
    },
  },
  data() {
    return {
      fields: [],
      selectedCountry: countries.find((country) => country.code === "DE"),
      query: "",
      isWeatherLoading: false,
      weather: null,
      form: {},
      debouncedLoadWeatherData: debounce(this.loadWeatherData, 300),
    };
  },
  watch: {
    selectedCountry() {
      this.debouncedLoadWeatherData();
    },
  },
  mounted() {
    this.assignAddProjectFields();
    this.loadWeatherData();
  },
  emits: ["showsuccesstrigger", "showsuccessmessage", "reloadmetadata"],
  methods: {
    assignAddProjectFields() {
      this.fields = [
        {
          field_name: "Projektname",
          field_identifier: "name",
          field_type: "text",
          placeholder: "",
        },
        {
          field_name: "Kunde",
          field_identifier: "customer_name",
          field_type: "text",
          placeholder: "Kleingeschrieben, ohne Leer- und Sonderzeichen (e.g. johannbunte)",
        },
        {
          field_name: "Land (Kürzel)",
          field_identifier: "country_code",
          field_type: "hidden",
          placeholder: "Country Code (e.g. DE, FR, ...)",
        },
        {
          field_name: "PLZ",
          field_identifier: "zip_code",
          field_type: "text",
          placeholder: "",
        },
        {
          field_name: "Straße",
          field_identifier: "street",
          field_type: "text",
          placeholder: "",
        },
        {
          field_name: "Startdatum",
          field_identifier: "planned_start",
          field_type: "date",
          placeholder: "",
        },
      ];
    },
    isCurrentCountryCode(countryCode, zipCode) {
      return this.selectedCountry?.code === countryCode && this.form.zip_code === zipCode;
    },
    loadWeatherData() {
      if (!this.selectedCountry || !this.form.zip_code) {
        this.isWeatherLoading = false;
        this.weather = null;
        return;
      }
      this.weather = null;
      const weatherCountryCode = this.selectedCountry.code;
      const weatherZipCode = this.form.zip_code;
      this.isWeatherLoading = true;
      WeatherRepository.loadByCountryCode(weatherCountryCode, weatherZipCode)
        .then((result) => {
          if (this.isCurrentCountryCode(weatherCountryCode, weatherZipCode)) {
            this.weather = result;
          }
        })
        .catch((error) => {
          logger.error(error);
        })
        .finally(() => {
          if (this.isCurrentCountryCode(weatherCountryCode, weatherZipCode)) {
            this.isWeatherLoading = false;
          }
        });
    },
    addProject(submitEvent) {
      if (
        (!this.weather || !this.weather.item) &&
        !window.confirm("No weather data found, are you sure to continue?")
      ) {
        return;
      }

      const newProject = {
        name: submitEvent.target.elements.name.value,
        customer_name: submitEvent.target.elements.customer_name.value,
        country_code: this.selectedCountry.code,
        zip_code: submitEvent.target.elements.zip_code.value,
        street: submitEvent.target.elements.street.value,
        planned_start: parse(
          submitEvent.target.elements.planned_start.value,
          "yyyy-MM-dd",
          new Date(),
        ),
        planned_end: null,
        status: "active",
        features: ["camera", "daily_report"],
        timezone: "Europe/Berlin",
        tracking_status: false,
        tracking_entity: null,
      };

      ProjectRepository.createProject(newProject)
        .then((createdProject) => {
          this.$emit("reloadmetadata", createdProject);
          this.$emit("showsuccesstrigger", true);
          this.$emit("showsuccessmessage", "Das Projekt wurde erfolgreich erstellt!");
        })
        .catch((error) => {
          logger.error(error);
        });
    },
    handleInputChange(field, event) {
      field.error = null;
      this.form[field.field_identifier] = event.target.value;
      if (field.field_identifier === "zip_code") {
        this.debouncedLoadWeatherData();
      }
    },
  },
};
</script>
