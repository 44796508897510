<template>
  <div class="flex flex-col w-80 h-full bg-white border-l border-gray-300">
    <div class="flex-1">
      <div class="pl-5 pr-3 py-2 border-b border-gray-300 flex items-center justify-between">
        <span class="text-gray-600 font-bold"> Annotations </span>

        <div class="flex gap-3 items-center">
          <ShareIcon class="h-4 w-4 cursor-pointer text-gray-600" @click="handleShareAnnotation" />
          <InformationCircleIcon
            class="h-5 w-5 cursor-pointer text-gray-600"
            @click="isShortcutsOpen = true"
          />
        </div>
      </div>

      <ul class="focus flex-1 text-xs pt-4 font-mono" @mouseleave="handleGroupListUnHover">
        <li
          v-for="annotationGroup in annotationGroups"
          :key="annotationGroup.id"
          class="relative text-xs cursor-pointer w-full select-none py-1 pl-2 pr-3 flex hover:bg-gray-200 justify-between"
          @mouseenter="handleGroupHover(annotationGroup.groupId)"
        >
          <div class="flex gap-2 items-center">
            <div class="w-1 h-4" :style="`background: ${annotationGroup.color.main}`" />

            <span v-if="annotationGroup.processClass !== 'no_class'">
              #{{ annotationGroup.groupId }}
            </span>
            <span>
              {{ annotationGroup.processClass }} ({{ annotationGroup.annotations.length }})
            </span>
          </div>
          <div>
            <TrashIcon
              v-if="annotationGroup.processClass !== 'no_class'"
              class="h-4 w-4 text-red"
              @click="groupService?.deleteGroup(annotationGroup)"
            />
          </div>
        </li>
      </ul>
    </div>

    <div class="flex-1">
      <p class="text-gray-600 px-5 py-2 border-b border-t border-gray-300 font-bold">
        Sequence groups
      </p>

      <ul class="focus text-xs pt-4 font-mono" @mouseleave="handleGroupListUnHover">
        <li
          v-for="sequenceGroup in sequenceGroups"
          :key="sequenceGroup.group_id"
          class="relative text-xs w-full select-none py-1 pl-2 pr-3 flex hover:bg-gray-200 justify-between"
          @mouseenter="handleGroupHover(sequenceGroup.group_id)"
        >
          <div
            class="flex gap-2 items-center cursor-pointer"
            v-if="sequenceGroup.process_class !== 'no_class'"
          >
            <div class="w-1 h-full" :style="`background: ${sequenceGroup.color.main}`" />

            <p class="max-w-52 break-words">
              #{{ sequenceGroup.group_id }} {{ sequenceGroup.process_class }}
            </p>
          </div>
          <div class="flex flex-wrap justify-end items-center">
            <ChevronLeftIcon
              class="h-4 w-4 cursor-pointer text-gray-600 5"
              @click="switchToClosestFrameGroup(sequenceGroup.group_id, 'prev')"
            />
            <ChevronRightIcon
              class="h-4 w-4 cursor-pointer text-gray-600 mr-1"
              @click="switchToClosestFrameGroup(sequenceGroup.group_id, 'next')"
            />
            <div v-if="getProcessBbox(sequenceGroup.group_id)">
              <EyeIcon
                v-if="getProcessBbox(sequenceGroup.group_id)?.visible"
                class="h-4 w-4 cursor-pointer text-gray-600 mr-1"
                @click="groupService?.toggleSequenceGroupVisibility(sequenceGroup)"
              />

              <EyeSlashIcon
                v-else
                class="h-4 w-4 cursor-pointer text-gray-600 mr-1"
                @click="groupService?.toggleSequenceGroupVisibility(sequenceGroup)"
              />
            </div>

            <TrashIcon
              class="h-4 w-4 cursor-pointer text-red"
              @click="groupService?.deleteSequenceGroup(sequenceGroup)"
            />
          </div>
        </li>
      </ul>
    </div>

    <div class="py-2 border-t border-gray-300 text-xs" v-if="groupService?.sequence">
      <div class="flex flex-col max-h-96 overflow-y-auto scroll-smooth" ref="commentSection">
        <div
          v-for="(comment, index) in groupService.sequence.comments"
          :key="index"
          class="px-5 flex py-2 gap-2 justify-between hover:bg-gray-200 relative group"
        >
          <div class="flex-1">
            <p class="font-medium font-mono">{{ formatCommenterName(comment.created_by) }}:</p>
            <p class="text-gray-600">{{ comment.message }}</p>
          </div>
          <span class="text-gray-600 w-min font-mono">{{ formatTime(comment.created) }}</span>
          <div
            class="cursor-pointer absolute top-0 right-0 h-full hidden group-hover:flex items-center bg-white p-3"
            @click="groupService?.deleteComment(index)"
          >
            <TrashIcon class="h-4 w-4 text-red" />
          </div>
        </div>
      </div>

      <div class="pt-2 px-5 flex gap-2 items-center">
        <textarea
          class="w-full h-16 border border-gray-300 rounded-md text-xs resize-none"
          v-model="commentText"
          placeholder="Add a comment..."
          @keydown="handleCommentInput"
        />

        <PaperAirplaneIcon class="h-4 w-4 cursor-pointer text-gray-600" @click="sendComment" />
      </div>
    </div>
    <div class="px-5 py-2 border-t border-gray-300 text-xs font-mono" v-if="groupService?.sequence">
      <div class="flex text-gray-600 justify-between">
        <span>Updated by:</span>
        <span>{{ groupService.sequence.updated_by }}</span>
      </div>
      <div class="flex text-gray-600 justify-between">
        <span>Last updated:</span>
        <span>{{ formatTime(groupService.sequence.updated) }}</span>
      </div>
    </div>
  </div>
  <ShortcutsModal v-model="isShortcutsOpen" />
</template>

<script setup lang="ts">
import {
  TrashIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  EyeIcon,
  EyeSlashIcon,
  PaperAirplaneIcon,
  ShareIcon,
  InformationCircleIcon,
} from "@heroicons/vue/24/outline";
import { format } from "date-fns";
import { computed, onMounted, PropType, ref } from "vue";
import { useRoute } from "vue-router";
import { useCustomToast } from "shared/composables/toast";
import ShortcutsModal from "@/views/person_gad/components/ShortcutsModal.vue";
import CanvasService from "../services/canvasService";
import GroupService from "../services/groupService";

const props = defineProps({
  canvasService: {
    type: Object as PropType<CanvasService>,
  },
  groupService: {
    type: Object as PropType<GroupService>,
  },
});

const toast = useCustomToast();
const route = useRoute();

const commentText = ref("");
const commentSection = ref<HTMLDivElement | null>(null);
const isShortcutsOpen = ref(false);

const annotationGroups = computed(() => {
  if (props?.groupService?.currentFrameGroups) {
    return props.groupService.currentFrameGroups.slice().sort((a, b) => a.groupId - b.groupId);
  }

  return [];
});

const sequenceGroups = computed(() => {
  const groupService = props.groupService;

  if (groupService) {
    return groupService.sequenceGroups
      .map((group) => ({
        ...group,
        color: groupService.getProcessClassColor(group.process_class),
      }))
      .sort((a, b) => a.group_id - b.group_id);
  }

  return [];
});

onMounted(() => {
  setTimeout(() => {
    commentSection.value?.scroll(0, commentSection.value.scrollHeight);
  }, 300);
});

const handleGroupListUnHover = () => {
  props.canvasService?.revertAnnotationsHighlight();
};

const handleGroupHover = (groupId: number) => {
  const annotationGroup = annotationGroups.value.find((group) => group.groupId === groupId);
  if (!annotationGroup) {
    return;
  }

  props.canvasService?.highlightAnnotations(annotationGroup.annotations);
};

const switchToClosestFrameGroup = (groupId: number, direction: "prev" | "next") => {
  const closestFrameIndex = props.groupService?.getClosestFrameIndexWithGroup(groupId, direction);

  if (closestFrameIndex !== undefined && closestFrameIndex !== props.groupService?.frameIndex) {
    props.groupService?.setFrameIndex(closestFrameIndex);
  }
};

const getProcessBbox = (groupId: number) => {
  return props.groupService?.processBboxes?.find((bbox) => bbox.group_id === groupId);
};

const formatTime = (date: Date) => {
  return format(date, "dd.MM.yy HH:mm:ss");
};

const formatCommenterName = (name: string) => {
  return name.split("@")[0];
};

const sendComment = () => {
  if (!commentText.value) {
    return;
  }

  props.groupService?.sendComment(commentText.value);
  commentText.value = "";

  setTimeout(() => {
    commentSection.value?.scroll(0, commentSection.value.scrollHeight);
  }, 300);
};

const handleCommentInput = (event: KeyboardEvent) => {
  event.stopPropagation();

  if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
    sendComment();
  }
};

const handleShareAnnotation = () => {
  const url = `${window.location.origin}${route.path}`;
  navigator.clipboard.writeText(url);
  toast.success("Link copied to clipboard");
};
</script>
