<template>
  <MainLayout activeItem="PRD">
    <div class="flex flex-col flex-1 overflow-hidden">
      <div
        class="flex justify-between items-center bg-gray-50 px-6 py-4 gap-6 flex-col lg:flex-row"
      >
        <router-link
          :to="{
            name: 'ValidationPrdOverview',
            query: {
              start: format(startDate, urlDateFormat),
              end: format(endDate, urlDateFormat),
            },
          }"
          class="inline-flex items-center rounded-md border border-transparent bg-gray-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
        >
          <ArrowLeftIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
          Back to overview
        </router-link>
      </div>
      <div class="flex items-center justify-center flex-1 overflow-auto" v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div class="flex flex-col flex-1 overflow-auto p-4 gap-6" v-if="!isLoading">
        <div
          v-for="item in batchCalendarWeeks"
          :key="item.calendarWeek"
          class="flex flex-col gap-1"
        >
          <router-link
            :to="{
              name: 'QualitySamplingDetailView',
              query: {
                start: format(item.start, urlDateFormat),
                end: format(item.end, urlDateFormat),
              },
            }"
            class="bg-gray-200 px-2 py-1 rounded w-min whitespace-nowrap"
            >{{ `KW ${item.calendarWeek.toString().slice(5).padStart(2, "0")}` }}</router-link
          >
          <div class="flex flex-col gap-1">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3 px-3 text-left text-sm font-semibold text-gray-900">
                    Name
                  </th>
                  <th
                    scope="col"
                    v-if="hasAdminPermission"
                    class="py-3 px-3 text-center text-sm font-semibold text-gray-900"
                  >
                    Tracking Entity
                  </th>
                  <th scope="col" class="px-3 py-3 text-center text-sm font-semibold text-gray-900">
                    Percentage
                  </th>
                  <th scope="col" class="px-3 py-3 text-center text-sm font-semibold text-gray-900">
                    Quality Score
                  </th>
                  <th scope="col" class="px-3 py-3 text-center text-sm font-semibold text-gray-900">
                    Reviews
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3 text-center text-sm font-semibold text-gray-900"
                    v-for="errorType in errorTypes"
                    :key="errorType"
                  >
                    {{ errorType }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="batch in batchesByCalendarWeek[item.calendarWeek]" :key="batch._id">
                  <td class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                    <router-link
                      :to="{
                        name: 'QualitySamplingDetailView',
                        query: {
                          start: format(item.start, urlDateFormat),
                          end: format(item.end, urlDateFormat),
                          batch_id: batch._id,
                        },
                      }"
                      class="truncate"
                      >{{ batch.name }}</router-link
                    >
                  </td>
                  <td
                    v-if="hasAdminPermission"
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center"
                  >
                    <TrackingEntityTag
                      :entity="getTrackingEntity(batch.tracking_entity)"
                    ></TrackingEntityTag>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                    {{ formatPercentage(batch.percentage) }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                    {{ formatPercentage(qualityScoreByBatchId[batch._id].score) }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                    {{ totalReviewsCountByBatchId[batch._id] }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center"
                    v-for="errorType in errorTypes"
                    :key="errorType"
                  >
                    {{
                      !areOpsReviewedProcessesWithErrorsLoading && errorsByTypeByBatchId[batch._id]
                        ? errorsByTypeByBatchId[batch._id][errorType]
                        : null
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="px-3 py-2 border-t w-full border-solid border-t-gray-200 group-hover:bg-yellow-200 font-semibold"
          v-if="
            filteredBatches && opsReviewBatches && filteredBatches.length < opsReviewBatches.length
          "
        >
          <button
            @click="numberCalendarWeeks += 4"
            class="flex items-center hover:text-white hover:bg-orange bg-gray-100 px-4 py-2 rounded-md leading-none border"
          >
            <ArrowDownIcon class="h-4 pr-1" />Load More
          </button>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script setup lang="ts">
import { ArrowLeftIcon, ArrowDownIcon } from "@heroicons/vue/20/solid";
import { addDays, format, getISOWeek, getISOWeekYear } from "date-fns";
import { min } from "date-fns";
import { computed, ref } from "vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { useHasPermission } from "shared/composables/project";
import { useTrackingEntities } from "shared/composables/validation";
import MainLayout from "@/components/layout/MainLayout.vue";
import TrackingEntityTag from "@/components/other/TrackingEntityTag.vue";
import {
  OpsReviewBatch,
  OpsReviewedProcessErrorType,
  OpsReviewedProcessWithErrors,
  OpsReviewQualityScore,
} from "@/types/Validation";
import { useStartEndDate } from "@/views/process_validation/composables/general";
import { useOpsReviewBatches } from "@/views/process_validation/composables/opsReviewBatch";
import { useOpsReviewedProcessesWithErrorsForBatches } from "@/views/process_validation/composables/validation";
import {
  calculateErrorsByType,
  calculateQualityScore,
} from "@/views/process_validation/services/qualityScore";
import { formatPercentage, urlDateFormat } from "@/views/process_validation/services/validation";

const { startDate, endDate } = useStartEndDate();
const hasAdminPermission = useHasPermission("pct_admin");
const { opsReviewBatches, isLoading: areOpsReviewBatchesLoading } = useOpsReviewBatches();
const numberCalendarWeeks = ref<number>(4);
const { trackingEntities } = useTrackingEntities();

const batchCalendarWeeks = computed(() => {
  const calendarWeeks = [
    ...new Set(
      (opsReviewBatches.value || []).map((batch) => {
        const startDate = new Date(batch.start_date_local);
        const year = getISOWeekYear(startDate);
        const week = getISOWeek(startDate);
        return `${year}-${week}`;
      }),
    ),
  ];

  calendarWeeks.sort((a, b) => b.localeCompare(a));

  return calendarWeeks
    .map((calendarWeek) => {
      const batch = batchesByCalendarWeek.value[calendarWeek][0];
      return {
        calendarWeek,
        start: batch.start_date_local,
        end: addDays(batch.start_date_local, 6),
      };
    })
    .slice(0, numberCalendarWeeks.value);
});

const earliestStartBatchSelection = computed(() =>
  batchCalendarWeeks.value?.length
    ? min(batchCalendarWeeks.value.map((item) => new Date(item.start)))
    : null,
);

const filteredBatches = computed(() =>
  opsReviewBatches.value?.filter(
    (item) =>
      earliestStartBatchSelection.value &&
      item.start_date_local >= earliestStartBatchSelection.value,
  ),
);

const filteredBatchIds = computed(() => filteredBatches.value?.map((batch) => batch._id));

const batchesByCalendarWeek = computed(() => {
  const sortedBatches = (opsReviewBatches.value || []).slice();
  sortedBatches.sort((a, b) => a.name.localeCompare(b.name));

  return sortedBatches.reduce((acc, batch) => {
    const startDate = new Date(batch.start_date_local);
    const year = getISOWeekYear(startDate);
    const week = getISOWeek(startDate);
    const key = `${year}-${week}`;

    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(batch);
    return acc;
  }, {} as Record<string, OpsReviewBatch[]>);
});

const { opsReviewedProcessesWithErrors, isLoading: areOpsReviewedProcessesWithErrorsLoading } =
  useOpsReviewedProcessesWithErrorsForBatches(filteredBatchIds);

const allProcessesWithErrorsByBatchId = computed(() =>
  (opsReviewedProcessesWithErrors.value || []).reduce((acc, processWithErrors) => {
    for (const batchId of processWithErrors.batch_ids) {
      if (!acc[batchId]) {
        acc[batchId] = [];
      }
      acc[batchId].push(processWithErrors);
    }
    return acc;
  }, {} as Record<string, OpsReviewedProcessWithErrors[]>),
);

const qualityScoreByBatchId = computed(() =>
  (filteredBatches.value || []).reduce((acc, batch) => {
    const processesWithErrors = allProcessesWithErrorsByBatchId.value[batch._id] || [];
    acc[batch._id] = calculateQualityScore(processesWithErrors);
    return acc;
  }, {} as Record<string, OpsReviewQualityScore>),
);

const totalReviewsCountByBatchId = computed(() =>
  (filteredBatches.value || []).reduce((acc, batch) => {
    acc[batch._id] = [
      ...new Set(
        allProcessesWithErrorsByBatchId.value[batch._id]?.map((process) =>
          [process.customer_name, process.site_id, process.camera_id, process.date].join("_"),
        ),
      ),
    ].length;
    return acc;
  }, {} as Record<string, number>),
);

const errorTypes = computed(() => {
  const types: OpsReviewedProcessErrorType[] = [
    "process_missing",
    "wrong_process",
    "process_did_not_occur",
    "wrong_level",
    "wrong_time",
    "wrong_people_count",
    "wrong_bounding_box_size",
  ];
  types.sort();
  return types;
});

const errorsByTypeByBatchId = computed(() =>
  (filteredBatches.value || []).reduce((acc, batch) => {
    acc[batch._id] = calculateErrorsByType(allProcessesWithErrorsByBatchId.value[batch._id] ?? []);
    return acc;
  }, {} as Record<string, Record<OpsReviewedProcessErrorType, number>>),
);

const isLoading = computed(() => areOpsReviewBatchesLoading.value);

const getTrackingEntity = (entityId: string) => {
  return trackingEntities.value?.find((entity) => entity._id === entityId);
};
</script>
