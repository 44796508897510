<template>
  <Modal :open="open" @close="open = false">
    <template #title>Shortcuts</template>
    <template #content>
      <div class="w-96 text-sm flex-col flex gap-6">
        <div v-for="shortcut in shortcuts" :key="shortcut.name">
          <h3 class="text-sm font-semibold text-gray-500 uppercase mb-2">{{ shortcut.name }}</h3>
          <ul>
            <li
              v-for="(item, index) in shortcut.items"
              :key="item.name"
              class="flex justify-between items-center py-2 -mx-4 sm:-mx-6 px-4 sm:px-6"
              :class="index === 0 ? '' : 'border-t border-gray-100'"
            >
              <span class="text-gray-700">{{ item.name }}</span>
              <div class="flex gap-1">
                <button
                  v-for="(key, index) in item.shortcut"
                  :key="index"
                  class="px-1 py-0.5 bg-gray-200 rounded-md text-gray-700"
                >
                  <kbd>{{ key }}</kbd>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from "shared/components/modals/Modal.vue";

const open = defineModel<boolean>({ required: true });

const isMacos = navigator.userAgent.toLowerCase().includes("mac os x");

const shortcuts = [
  {
    name: "General",
    items: [
      {
        name: "Save",
        shortcut: [`${isMacos ? "Cmd" : "Ctrl"} + S`],
      },
      {
        name: "Toggle name in textbox visibility",
        shortcut: ["H"],
      },
      {
        name: "Open Context Menu",
        shortcut: ["RightClick (on bbox)"],
      },
      {
        name: "Switch frames",
        shortcut: ["A", "D", "LeftArrow", "RightArrow"],
      },
    ],
  },
  {
    name: "Bbox",
    items: [
      {
        name: "Draw bbox",
        shortcut: ["Ctrl + Click"],
      },
      {
        name: "Delete bbox",
        shortcut: ["Delete", "R"],
      },
      {
        name: "Move bbox",
        shortcut: ["I", "J", "K", "L"],
      },
      {
        name: "Assign bbox to group by group-id",
        shortcut: ["1-9"],
      },
      {
        name: 'Assign bbox to "no_class" group',
        shortcut: ["0", "W"],
      },
      {
        name: 'Assign "support" label to the bbox/group',
        shortcut: ["F"],
      },
      {
        name: 'Assign "passive" label to the bbox/group',
        shortcut: ["P"],
      },
    ],
  },
];
</script>

<style scoped>
kbd {
  background-color: #e5e7eb;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 0.75rem;
  font-family: monospace;
  font-weight: 500;
}
</style>
